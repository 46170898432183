<template>
  <div class="content">
    <div class="background">
      <div class="row">
        <div class="col">
          <h4>Ordering Strategies Documentation</h4>
        </div>
        <div class="col-auto">
          <router-link to="/ordering-strategies" class="mb-4 vave-btn">BACK TO ORDERING STRATEGIES</router-link>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <h4>Overview</h4>
          <div>
            <p>Ordering Strategies are a powerful tool for customizing the order of products on your online store. By creating and editing your own strategy, you can prioritize specific products to be displayed first, highlight new releases, or showcase items that are on sale. This guide will walk you through the steps of creating and assigning an Ordering Strategy to a Category or Product carousel</p>
            <br />
            <p>Step 1: create a new Ordering Strategy</p>
            <img :src="images.create" width="1000" />
            <br />
            <br />
            <p>Step 2: edit the strategy by Adding the products you want to show first, then rearrange the order by dragging them around. Remember to click the Save button before leaving the page.</p>
            <img :src="images.order" width="1000" />
            <br />
            <br />
            <p>Step 3: assign the Strategy to a Category using the input selector</p>
            <img :src="images.categories" width="1000" />
            <br />
            <br />
            <p>To set the strategy on a carousel, open the widget configuration and set "strategy" as orderBy, and choose the strategy to use:</p>
            <img :src="images.carousel" width="1000" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      images: {
        create: require("./../assets/images/ordering/create.png"),
        order: require("./../assets/images/ordering/order.png"),
        categories: require("./../assets/images/ordering/categories.png"),
        carousel: require("./../assets/images/ordering/carousel.png"),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: white;
  padding: 20px;
}
img {
  border: 1px solid black;
}
</style>